import { cn } from '@paladise/ui/lib/utils'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
}

const PaddingContainer = ({ children, className }: Props) => {
  return <div className={cn('py-2', className)}>{children}</div>
}

export default PaddingContainer
