'use client'

import { Dialog } from '@paladise/ui/components/ui/dialog'
import { HASH_KEY, useHash } from '@paladise/utils/hooks/useHash'
import React, { useEffect, useState } from 'react'
import QrcodeModal from './QrcodeModal'

const QrcodeWrapper = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const hash = useHash()

  //fix replaceState no trigger rerender
  useEffect(() => {
    setOpen(hash === `#${HASH_KEY.DOWNLOAD_APP}`)
  }, [hash])

  return (
    <Dialog
      open={open}
      onOpenChange={open => {
        if (!open) {
          setOpen(false)
          history.replaceState(null, '', window.location.pathname)
        }
      }}
    >
      <QrcodeModal />
      {children}
    </Dialog>
  )
}

export default QrcodeWrapper
