import {
  EmailSchema,
  PasswordSchema,
  UsernameSchema,
} from 'utils/user-validation'
import { z } from 'zod'

export const UpdateEmailSchema = z.object({
  email: EmailSchema,
})

export const ResendEmailSchema = z.object({
  email: EmailSchema,
})

export const UpdateUsernameSchema = z.object({
  username: UsernameSchema,
})

export const ChangePasswordSchema = z
  .object({
    currentPassword: PasswordSchema,
    newPassword: PasswordSchema,
    confirmPassword: PasswordSchema,
  })
  .superRefine(({ currentPassword, newPassword, confirmPassword }, ctx) => {
    if (currentPassword === newPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'password_same_as_old',
        path: ['newPassword'],
      })
    }
    if (newPassword !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'password_confirmation_match',
        path: ['confirmPassword'],
      })
    }
  })

export const AddPasswordSchema = z
  .object({
    newPassword: PasswordSchema,
    confirmPassword: PasswordSchema,
  })
  .superRefine(({ newPassword, confirmPassword }, ctx) => {
    if (newPassword !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'password_confirmation_match',
        path: ['confirmPassword'],
      })
    }
  })

export const ResetPasswordSchema = z
  .object({
    newPassword: PasswordSchema,
    confirmPassword: PasswordSchema,
  })
  .superRefine(({ newPassword, confirmPassword }, ctx) => {
    if (newPassword !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'password_confirmation_match',
        path: ['confirmPassword'],
      })
    }
  })

export const SubscriptionCompletedSchema = z.object({
  username: z.string({ required_error: 'required' }),
  email: EmailSchema,
})

export const DeleteAccountSchema = z.object({
  reason: z
    .string({ required_error: 'required' })
    .min(1, { message: 'required' }),
})

export const CancelReasonSchema = z.object({
  reasons: z.array(z.string()),
  other_reason: z.string(),
})

export type UpdateUsernameSchemaType = z.infer<typeof UpdateUsernameSchema>
export type UpdateEmailSchemaType = z.infer<typeof UpdateEmailSchema>
export type ChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>
export type AddPasswordSchemaType = z.infer<typeof AddPasswordSchema>
export type ResetPasswordSchemaType = z.infer<typeof ResetPasswordSchema>
export type ResendEmailSchemaType = z.infer<typeof ResendEmailSchema>
export type DeleteAccountSchemaType = z.infer<typeof DeleteAccountSchema>
export type CancelReasonSchemaType = z.infer<typeof CancelReasonSchema>
