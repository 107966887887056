'use client'

import { INFLUENCER_COOKIE_NAME } from '@paladise/config/constants'
import { Button } from '@paladise/ui/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '@paladise/ui/components/ui/dialog'
import Cookies from 'js-cookie'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

export const InfluencerNotifyDialog = ({
  cookie,
}: {
  cookie: string | undefined
}) => {
  const t = useTranslations()

  const [isOpen, setIsOpen] = useState(cookie ? cookie === 'true' : false)

  useEffect(() => {
    if (cookie) {
      Cookies.set(INFLUENCER_COOKIE_NAME, 'false')
    }
  }, [cookie])

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <DialogContent
        className="flex h-fit w-[600px] max-w-[600px] flex-col justify-start"
        aria-describedby={undefined}
      >
        <DialogTitle className="mb-[10px] flex h-fit flex-col items-start justify-center gap-[10px]">
          <p className="i-ps-crown_rou_f text-palup-brand-yellow self-center pb-1 pt-2 [--icon-size:64px]"></p>
          <p className="text-title text-label-l1 w-full">
            {t('membership_verified_title')}
          </p>
        </DialogTitle>
        <DialogDescription className="text-body">
          {t('membership_verified_desc')}
        </DialogDescription>
        <DialogFooter>
          <Button
            variant="button-p1"
            className="py-[15px]"
            onClick={() => setIsOpen(false)}
          >
            {t('ok')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
