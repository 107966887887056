'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@paladise/ui/components/TextField'
import { Button } from '@paladise/ui/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@paladise/ui/components/ui/dialog'
import { cn } from '@paladise/ui/lib/utils'
import { resetUserPassword } from 'api/user'
import PaddingContainer from 'features/bot/components/PaddingContainer'
import { clientFetch } from 'lib/fetch/client'
import { type IBaseFetchError } from 'lib/fetch/type'
import { useTranslations } from 'next-intl'
import { useEffect, useState, useTransition } from 'react'
import { useForm } from 'react-hook-form'
import useToast from 'utils/hooks/useToast'
import { ResendEmailSchema, type ResendEmailSchemaType } from '../../schema'
import './dialog.css'

export const ResetPasswordDialog = ({ className }: { className?: string }) => {
  const t = useTranslations()
  const [open, setOpen] = useState(false)
  const [isLoading, startTransition] = useTransition()
  const { showSuccessToast } = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    watch,
    reset,
  } = useForm<ResendEmailSchemaType>({
    resolver: zodResolver(ResendEmailSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })

  const watchEmail = watch('email')

  const getErrorMessage = (errorType: string | undefined | {}) => {
    if (!errorType) return ''
    switch (errorType) {
      case 'user not found.':
        return t('user_not_found')
      case 'incorrect_email_format':
        return t('incorrect_email_format')
      case 'required':
      default:
        return t('required')
    }
  }

  const onSubmitResetPassword = (data: ResendEmailSchemaType) => {
    startTransition(async () => {
      try {
        await resetUserPassword(clientFetch, {
          email: data.email,
        })
        showSuccessToast({})
        setOpen(false)
      } catch (error) {
        const errorMessage = (error as IBaseFetchError)?.message
        if (errorMessage) {
          setError('email', { message: errorMessage })
        }
      }
    })
  }

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button
          type="button"
          className="text-caption text-label-l2 decoration-label-l2 ml-auto w-fit cursor-pointer underline"
          onClick={() => {
            setOpen(true)
          }}
        >
          {t('forgot_password')}
        </button>
      </DialogTrigger>
      <DialogContent
        showClose
        className={cn(
          'min600:max-w-[600px] min600:w-[600px] h-auto p-2',
          className,
        )}
      >
        <DialogTitle className="dialog-title">
          {t('reset_password')}
        </DialogTitle>
        <DialogDescription className="dialog-description">
          {t('reset_password_description')}
        </DialogDescription>
        <form
          onSubmit={e => {
            e.stopPropagation()
            handleSubmit(onSubmitResetPassword)(e)
          }}
          noValidate
        >
          <PaddingContainer className="px-4 pb-[30px] pt-2">
            <TextField
              {...register('email')}
              type="email"
              placeholder={t('email')}
              isInvalid={!!errors.email}
              errorMessage={getErrorMessage(errors.email?.message)}
              containerProps={{ className: 'space-y-[6px]' }}
            />
          </PaddingContainer>
          <div className="grid grid-cols-1 p-4">
            <Button
              className="w-full"
              variant="primary"
              type="submit"
              loading={isLoading}
              disabled={
                !!errors.email?.message || isLoading || !watchEmail || !isDirty
              }
            >
              {t('send')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
