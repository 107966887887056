import { createSelectors } from '@paladise/utils/zustand-selector'
import { subscribeWithSelector } from 'zustand/middleware'
import { createWithEqualityFn as create } from 'zustand/traditional'

export type PopupState = {
  mask: {
    auth: boolean
    verifyEmail: boolean
  }
}

export type PopupActions = {
  setAuthPopup: (isOpen: boolean) => void
  setVerifyEmailPopup: (isOpen: boolean) => void
}

export type PopupStore = PopupState & PopupActions

export const usePopupStoreBase = create<PopupStore>()(
  subscribeWithSelector((set, get) => ({
    mask: {
      auth: false,
      verifyEmail: false,
    },
    setAuthPopup: isOpen => {
      set({ mask: { ...get().mask, auth: isOpen } })
    },
    setVerifyEmailPopup: isOpen => {
      set({ mask: { ...get().mask, verifyEmail: isOpen } })
    },
  })),
)

export const usePopupStore = createSelectors(usePopupStoreBase)
