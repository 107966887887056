'use client'

import { CDN_URL } from '@paladise/config/constants'
import { Button } from '@paladise/ui/components/ui/button'
import { ROUTE_PATH } from 'config/routerPath'
import {
  ThirdParty,
  ThirdPartyAuthMode,
  type ThirdPartyAuthModeType,
} from 'features/auth/constants'
import { useTranslations } from 'next-intl'
import { usePathname } from 'next/navigation'
import { useAuthStore } from 'store/auth-store'
import { redirectThirdParty } from '../actions/redirect-third-party'

const ThirdPartyAuth = ({ mode }: { mode: ThirdPartyAuthModeType }) => {
  const t = useTranslations()
  const pathname = usePathname()
  const isIncludeContentPermission =
    useAuthStore.getState().isIncludeContentPermission

  const isLoginMode = mode === ThirdPartyAuthMode.Login

  const authProviders = [
    {
      provider: ThirdParty.auth.google,
      redirect_uri: isIncludeContentPermission
        ? `${ROUTE_PATH.SIDEBAR.CREATED_BY_YOU}`
        : pathname,
      image: `${CDN_URL}/palup/consumer/google_login_v2_3.svg`,
      text: isLoginMode ? t('log_in_with_google') : t('sign_up_with_google'),
      imageAlt: 'google-icon',
    },
    {
      provider: ThirdParty.auth.instagram,
      redirect_uri: isIncludeContentPermission
        ? `${ROUTE_PATH.SIDEBAR.CREATED_BY_YOU}`
        : pathname,
      image: `${CDN_URL}/palup/consumer/instagram_login_v2_3.svg`,
      text: isLoginMode
        ? t('log_in_with_instagram')
        : t('sign_up_with_Instagram'),
      imageAlt: 'instagram-icon',
    },
    {
      provider: ThirdParty.auth.tiktok,
      redirect_uri: isIncludeContentPermission
        ? `${ROUTE_PATH.SIDEBAR.CREATED_BY_YOU}`
        : pathname,
      image: `${CDN_URL}/palup/consumer/tiktok_login_v2_3.svg`,
      text: isLoginMode ? t('log_in_with_tiktok') : t('sign_up_with_tiktok'),
      imageAlt: 'tiktok-icon',
    },
  ]

  return (
    <section className="flex flex-col gap-[12px]">
      {/* TODO: Add Facebook login */}
      {/* <Button
        onClick={() => redirectThirdParty(ThirdParty.auth.facebook, 'enterprise')}
        variant="base"
        className="h-[48px] w-full gap-2"
      >
        <img src="/images/facebook_button.svg" alt="facebook-icon" width={24} />
        {t('continue_with_facebook')}
      </Button> */}
      {authProviders.map(provider => (
        <Button
          key={provider.provider}
          onClick={() =>
            redirectThirdParty({
              provider: provider.provider,
              redirect_uri: provider.redirect_uri,
              isIncludeContentPermission:
                provider.provider === ThirdParty.auth.instagram ||
                provider.provider === ThirdParty.auth.tiktok ||
                (provider.provider === ThirdParty.auth.google &&
                  isIncludeContentPermission),
            })
          }
          variant="gray-gray2"
          className="text-callout h-fit gap-1 py-[15px]"
          size="lg"
        >
          <img
            src={provider.image}
            alt={provider.imageAlt}
            className="text-gray-gray5 size-5"
          />
          <span>{provider.text}</span>
        </Button>
      ))}
    </section>
  )
}

export default ThirdPartyAuth
