import { useEffect } from 'react'
import { getScreenPageName } from '../utils/getScreenPageName'
import { useSendTracker } from './useSendTracker'

export const usePageNameTracker = (
  type: 'biz' | 'general',
  pathname: string,
) => {
  const { handleScreenTracker } = useSendTracker()

  useEffect(() => {
    const screenName = getScreenPageName({ type, pathname })
    handleScreenTracker({ screenName })
  }, [pathname, type, handleScreenTracker])
}
