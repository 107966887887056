import { createSelectors } from '@paladise/utils/zustand-selector'
import { subscribeWithSelector } from 'zustand/middleware'
import { createWithEqualityFn as create } from 'zustand/traditional'

export type AuthState = {
  isIncludeContentPermission: boolean
}

export type AuthActions = {
  setIsIncludeContentPermission: (isIncludeContentPermission: boolean) => void
}

export type AuthStore = AuthState & AuthActions

export const useAuthStoreBase = create<AuthStore>()(
  subscribeWithSelector((set, get) => ({
    isIncludeContentPermission: false,
    setIsIncludeContentPermission: isIncludeContentPermission => {
      set({ isIncludeContentPermission })
    },
  })),
)

export const useAuthStore = createSelectors(useAuthStoreBase)
