'use client'

import React from 'react'
import { type Context } from './types'

const trackerContext = React.createContext<Context | null>(null)

export const TrackerProvider = ({
  value,
  children,
}: {
  value: Context
  children: React.ReactNode
}) => {
  return (
    <trackerContext.Provider value={value}>{children}</trackerContext.Provider>
  )
}

export const useTracker = () => {
  const context = React.useContext(trackerContext)
  if (!context) {
    throw new Error('useTracker must be used within a TrackerProvider')
  }
  return context
}
