'use client'

import { BINDING_THIRD_PARTY_COOKIE_NAME } from '@paladise/config/constants'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@paladise/ui/components/ui/alert-dialog'
import { Button } from '@paladise/ui/components/ui/button'
import Cookies from 'js-cookie'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

export const ThirdPartyBindFailedDialog = ({
  cookie,
}: {
  cookie: string | undefined
}) => {
  const t = useTranslations()
  const [isOpen, setIsOpen] = useState(cookie ? cookie !== 'true' : false)

  useEffect(() => {
    if (cookie) {
      Cookies.set(BINDING_THIRD_PARTY_COOKIE_NAME, 'true')
    }
  }, [cookie])

  return (
    <AlertDialog open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <AlertDialogContent showClose className="w-[600px]">
        <AlertDialogHeader className="mb-[10px]">
          <AlertDialogTitle className="pr-5">Binding failed</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="pb-[12px]">
          Binding failed
        </AlertDialogDescription>
        <div className="mt-[16px] grid gap-2">
          <Button
            variant="primary"
            onClick={() => setIsOpen(false)}
            type="button"
          >
            {t('confirm')}
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
