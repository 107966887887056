import {
  PAGE_SCREEN_VIEW_TRACKER,
  type PageScreenViewTracker,
} from '../constants/screen'

/**
 * Retrieves the screen name based on the provided path name.
 * @param pathname - current URL's pathname
 * @returns screen name
 */
export function getScreenPageName({
  type,
  pathname,
}: {
  type: 'biz' | 'general'
  pathname: string
}): string {
  const paths = pathname.split('/').filter(path => path !== '')

  if (paths.length === 0) {
    return PAGE_SCREEN_VIEW_TRACKER['']
  }

  const isBiz = type === 'biz'
  const screenViewKey = ((isBiz ? 'biz-' : '') +
    paths.at(-1)) as PageScreenViewTracker

  return PAGE_SCREEN_VIEW_TRACKER[screenViewKey] || ''
}
