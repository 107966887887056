'use client'

import { Button } from '@paladise/ui/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@paladise/ui/components/ui/dialog'
import DividerWithText from 'components/DividerWithText'
import Logo from 'components/Logo'
import { PrivacyPolicy, UserTerms } from 'components/terms/Terms'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { usePopupStore } from 'store/popup-store'
import { AuthDialogEnum, ThirdPartyAuthMode } from '../constants'
import Login from './Login'
import SignUp from './SignUp'
import ThirdPartyAuth from './ThirdPartyAuth'
import { useSendTracker } from '@paladise/tracker/hooks/useSendTracker'
import { POPUP_SCREEN_VIEW_TRACKER } from '@paladise/tracker/constants/screen'

function AuthDialog() {
  const [type, setType] = useState<AuthDialogEnum>(AuthDialogEnum.Default)
  const isOpen = usePopupStore.use.mask().auth
  const setAuthPopup = usePopupStore.use.setAuthPopup()
  const t = useTranslations()
  const { handleScreenTracker } = useSendTracker()

  useEffect(() => {
    if (isOpen) return
    setType(AuthDialogEnum.Default) // Reset AuthDialog Type
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) return

    if (type === AuthDialogEnum.Default) {
      handleScreenTracker({
        screenName: POPUP_SCREEN_VIEW_TRACKER.signup_page,
      })
    }

    if (type === AuthDialogEnum.Login) {
      handleScreenTracker({
        screenName: POPUP_SCREEN_VIEW_TRACKER.login_page,
      })
    }
  }, [isOpen, type])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          setAuthPopup(false)
        }
      }}
    >
      <DialogContent
        className="flex h-[680px] w-[80%] max-w-[600px] flex-col justify-start pb-28"
        showClose
        aria-describedby={undefined}
      >
        <DialogTitle className="mb-10 flex h-[30px] justify-center">
          <Logo width={74} height={30} />
        </DialogTitle>

        {type === AuthDialogEnum.Default && (
          <div className="min768:w-[300px] mx-auto h-full w-full">
            <h2 className="text-label-l1 text-title mb-2">
              {t('sign_up_for_palup')}
            </h2>
            <p className="text-label-l2 text-body mb-8">
              {t('sign_up_promotion_message')}
            </p>

            <ThirdPartyAuth mode={ThirdPartyAuthMode.SignUp} />
            <DividerWithText className="mb-8 mt-6" text={t('or')} />

            <Button
              onClick={() => {
                setType(AuthDialogEnum.SignUp)
              }}
              className="text-label-l1 border-label-l3 bg-background-2nd mb-4 h-12 w-full border text-[15px] font-bold"
            >
              {t('create_new_account')}
            </Button>

            <p className="text-caption text-label-l2">
              {t.rich('sign_up_agreement', {
                t1: () => <UserTerms className="text-label-l1" />,
                t2: () => <PrivacyPolicy className="text-label-l1" />,
              })}
            </p>

            <p className="text-label-l1 text-body my-4">
              {t.rich('already_have_an_account_web', {
                t1: () => (
                  <Button
                    variant="text"
                    className="text-subtitle text-primary-p1 ml-1 p-0"
                    onClick={() => {
                      setType(AuthDialogEnum.Login)
                    }}
                  >
                    {t('log_in')}
                  </Button>
                ),
              })}
            </p>
          </div>
        )}

        {type === AuthDialogEnum.SignUp && (
          <SignUp
            onLogin={() => {
              setType(AuthDialogEnum.Login)
            }}
          />
        )}

        {type === AuthDialogEnum.Login && (
          <Login
            onSignUp={() => {
              setType(AuthDialogEnum.Default)
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AuthDialog
