import { generateScreenName } from '../utils/generateScreenName'

export const SCREEN_PAGE_SUFFIX = '_page'
export const SCREEN_POPUP_SUFFIX = '_popup'

export const SCREEN_TYPES = {
  PAGE: 'page',
  POPUP: 'popup',
} as const

export const SCREEN_EVENT_NAME = 'screen_view' as const

export const SCREEN_NAME_MAPPING = {
  // === general ===
  '': 'landing',
  error: 'error',
  birthday: 'birthday',
  ['delete-account']: 'deleteaccount',
  email: 'email',
  ['link-account']: 'linkaccount',
  password: 'password',
  username: 'username',
  login: 'login',
  signup: 'signup',
  ['reset-password']: 'reset_password',
  ['palup-rules']: 'userrules',
  ['user-terms-of-service']: 'usertermsofservice',
  discover: 'discover',
  home: 'avatar',
  info: 'avatar_info',
  payment: 'paymentmethod',
  edit: 'editavatar',
  ['edit-background']: 'knowledgebase',
  subscription: 'subscriptionedit',
  ['current-plan']: 'subscriptioncancel',
  ['payment-method']: 'paymentmethod',
  about: 'about',
  help: 'helpcenter',
  ['privacy-policy']: 'privacyterm',
  creators: 'creator_invite_about',
  // === biz ===
  ['biz-plans']: 'business_about_page',
} as const

export type SCREEN_NAME_MAPPING_TYPES = keyof typeof SCREEN_NAME_MAPPING

/**
 * Sample: chatroom_page
 * eg.
 * handleScreenTracker({
 *   screenName: EXCEPTIONAL_PAGE_SCREEN_VIEW_TRACKER.chatroom_page,
 *   parameters: {
 *     sponsored: isSponsored,
 *     is_boost: isBoost,
 *   },
 * })
 *
 * Use Scenario :
 * 1. handle screen tracker cannot access directly by router
 * 2. screen name do not need page suffix
 * 3. screen name need parameters
 */
export const EXCEPTIONAL_PAGE_SCREEN_VIEW_TRACKER = {
  faqs_page: 'faqs_page',
  faqsedit_page: 'faqsedit_page',
  selectpaymentmethod_page: 'selectpaymentmethod_page',
  webavatarresume_page: 'webavatarresume_page',
  menu_page: 'menu_page',
  feed: 'community_feed',
  subscriptions_page: 'subscriptions_page',
  avatar_profile_page: 'avatar_info_page',
  account_page: 'account_page',
  edit_headshot_page: 'editheadshot_page',
  chatroom_page: 'chatroom_page',
  delete_acount_confirm_page: 'deleteaccountconfirm_page',

  // biz
  biz_company_init_page: 'companyinformation_page',
  biz_cancel_seat_page: 'subscriptionpayment_cancelseates_page',
  biz_remove_seats_popup: 'removeseats_page',
  biz_plan_checkout_page: 'subscriptionpayment_page',
  biz_welcome_popup: 'subscriptionsuccessful_business_popup',
} as const

export const PAGE_SCREEN_VIEW_TRACKER = {
  ...Object.entries(SCREEN_NAME_MAPPING).reduce(
    (acc, [key, val]) => {
      acc[key as SCREEN_NAME_MAPPING_TYPES] = generateScreenName({
        key: val,
        screenType: SCREEN_TYPES.PAGE,
      })
      return acc
    },
    {} as Record<SCREEN_NAME_MAPPING_TYPES, string>,
  ),
  ...EXCEPTIONAL_PAGE_SCREEN_VIEW_TRACKER,
} as const

export type PageScreenViewTracker = keyof typeof PAGE_SCREEN_VIEW_TRACKER

export const POPUP_SCREEN_VIEW_TRACKER = {
  deleteaccount_popup: 'deleteaccount_popup',
  logout_popup: 'logout_popup',
  birthdayerror_popup: 'birthdayerror_popup',
  resume_popup: 'resume_popup',
  changepayment_popup: 'changepayment_popup',
  changepayment1_popup: 'changepayment1_popup',
  cancelplan_popup: 'cancelplan_popup',
  faqsmore_popup: 'faqsmore_popup',
  removequestion_popup: 'removequestion_popup',
  subscription_success_popup: 'subscriptionsuccessful_oa_popup',
  choosec_hatroom_popup: 'choosechatroom_popup',
  violationreport_popup: 'violationreport_popup',
  signup_page: 'signup_page',
  login_page: 'login_page',
} as const
