'use client'

import type { DATA_ATTRIBUTES } from '@paladise/tracker/types'
import React, { useState } from 'react'
import { cn } from '../lib/utils'
import { Input } from './ui/input'
import { Description, FieldError, Label } from './ui/label'
import { Textarea } from './ui/textarea'

export interface TextFieldProps
  extends Omit<React.ComponentProps<'input'>, 'onChange'> {
  label?: string | JSX.Element
  description?: string
  errorMessage?: string
  containerProps?: React.ComponentProps<'div'>
  dataAttributes?: DATA_ATTRIBUTES
  isInvalid?: boolean
  isViolated?: boolean
  numericOnly?: boolean
  disableEmojiAndSpecialSymbols?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function TextField({
  label,
  required,
  description,
  errorMessage,
  containerProps = {},
  dataAttributes,
  isViolated,
  numericOnly,
  disableEmojiAndSpecialSymbols,
  onChange,
  ...props
}: TextFieldProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (numericOnly) {
      value = value.replace(/[^\d]/g, '')
    }
    if (disableEmojiAndSpecialSymbols) {
      // filter emoji and special symbols
      value = value.replace(
        /[\u{1F300}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F000}-\u{1F02F}\u{1F0A0}-\u{1F0FF}\u{1F100}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F900}-\u{1F9FF}]/gu,
        '',
      )
      // enable chinese, japanese, korean, english, spanish and french
      value = value.replace(
        /[^\w\s\u4e00-\u9fff\u3040-\u309f\u30a0-\u30ff\uac00-\ud7af\u0080-\u024f\u3105-\u312F\u31A0-\u31BF\u02C7\u02CA\u02CB\u02D9\u0300-\u036f\-_]/g,
        '',
      )
    }
    e.target.value = value
    onChange?.(e)
  }

  return (
    <div
      {...containerProps}
      className={cn('flex flex-col space-y-[4px]', containerProps.className)}
    >
      {label && (
        <Label required={required} isViolated={isViolated}>
          {label}
        </Label>
      )}

      {description && <Description>{description}</Description>}
      <Input
        required={required}
        data-tracker-click={dataAttributes?.['data-tracker-click']}
        data-tracker-typing={dataAttributes?.['data-tracker-typing']}
        onChange={handleChange}
        inputMode={numericOnly ? 'numeric' : undefined}
        {...props}
      />
      {errorMessage && (
        // add div container to make sure data-attribute can be catch
        <div data-tracker-show={dataAttributes?.['data-tracker-show']}>
          <FieldError>{errorMessage}</FieldError>
        </div>
      )}
    </div>
  )
}

export interface TextAreaProps
  extends Omit<React.ComponentProps<'textarea'>, 'onChange'> {
  defaultValue?: string
  value?: string
  label?: string
  required?: boolean
  labelClassName?: string
  lengthClassName?: string
  labelExtra?: React.ReactNode
  placeholderExtra?: React.ReactNode
  description?: string
  showLength?: boolean
  errorMessage?: string
  containerProps?: React.ComponentProps<'div'>
  dataAttributes?: DATA_ATTRIBUTES
  isInvalid?: boolean
  isViolated?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export function TextAreaField({
  label,
  required,
  labelClassName,
  lengthClassName,
  labelExtra,
  placeholderExtra,
  description,
  errorMessage,
  showLength,
  rows,
  containerProps = {},
  onChange,
  dataAttributes,
  isViolated,
  ...props
}: TextAreaProps) {
  const [length, setLength] = useState(
    props.defaultValue?.length ?? props.value?.length ?? 0,
  )

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newLength = e.target.value.length
    setLength(newLength)
    onChange?.(e)
  }

  return (
    <div
      {...containerProps}
      className={cn('flex flex-col space-y-[4px]', containerProps.className)}
    >
      <section className="flex flex-col gap-1">
        {label && (
          <Label
            required={required}
            isViolated={isViolated}
            className={labelClassName}
          >
            {label}
          </Label>
        )}
        {labelExtra && (
          <div className="absolute right-0 top-0 !m-0">{labelExtra}</div>
        )}
        {description && <Description>{description}</Description>}
      </section>
      <Textarea
        data-tracker-click={dataAttributes?.['data-tracker-click']}
        data-tracker-typing={dataAttributes?.['data-tracker-typing']}
        rows={rows}
        onChange={handleChange}
        placeholderExtra={placeholderExtra}
        {...props}
      />

      <div className="flex w-full items-center justify-between">
        {errorMessage && (
          <FieldError className="text-alert text-caption min-w-0 flex-1 truncate">
            {errorMessage}
          </FieldError>
        )}
        {showLength && (
          <p
            className={cn(
              'length text-label-l2 ml-auto shrink-0 items-end text-right tabular-nums',
              lengthClassName,
            )}
          >
            {length}/{props.maxLength}
          </p>
        )}
      </div>
    </div>
  )
}
