'use client'

import { type ButtonProps } from '@paladise/ui/components/ui/button'
import { buttonVariants } from '@paladise/ui/components/ui/button.style'
import { cn } from '@paladise/ui/lib/utils'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import * as React from 'react'

const AlertDialog = AlertDialogPrimitive.Root

const AlertDialogTrigger = AlertDialogPrimitive.Trigger

const AlertDialogPortal = AlertDialogPrimitive.Portal

const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 bg-overlay-light fixed inset-0 z-50',
      className,
    )}
    {...props}
    ref={ref}
  />
))
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName

const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content> & {
    showClose?: boolean
    closeClassName?: string
    position?: 'bottom' | 'center'
  }
>(
  (
    {
      className,
      position = 'center',
      children,
      showClose,
      closeClassName,
      ...props
    },
    ref,
  ) => (
    <AlertDialogPortal>
      <AlertDialogOverlay />
      <AlertDialogPrimitive.Content
        ref={ref}
        className={cn(
          'bg-background-1st data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-left-1/2 fixed left-[50%] z-50 grid max-w-[480px] translate-x-[-50%] px-6 pb-6 pt-5 duration-200',
          position === 'center' &&
            'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-top-[48%] top-[50%] w-[330px] translate-y-[-50%] rounded-[20px]',
          position === 'bottom' &&
            'data-[state=closed]:slide-out-to-bottom-[48%] data-[state=open]:slide-in-from-bottom-[48%] bottom-0 max-h-full w-full rounded-t-[20px]',
          className,
        )}
        {...props}
      >
        {children}

        {showClose && (
          <AlertDialogPrimitive.Cancel
            className={cn(
              'absolute right-6 top-5 flex rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none',
              closeClassName,
            )}
          >
            <span className="i-ps-close [--icon-size:24px]" />
            <span className="sr-only">Close</span>
          </AlertDialogPrimitive.Cancel>
        )}
      </AlertDialogPrimitive.Content>
    </AlertDialogPortal>
  ),
)
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName

const AlertDialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-col space-y-2 text-left sm:text-left', className)}
    {...props}
  />
)
AlertDialogHeader.displayName = 'AlertDialogHeader'

const AlertDialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'min900:flex-row min900:[&>button]:flex-1 mt-4 flex w-full flex-col gap-2',
      className,
    )}
    {...props}
  />
)
AlertDialogFooter.displayName = 'AlertDialogFooter'

const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, children, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    ref={ref}
    className={cn('text-title flex items-center gap-3 text-left', className)}
    {...props}
  >
    <span className="i-ps-alert_tri_for_squ_f text-alert shrink-0 [--icon-size:24px]"></span>
    {children}
  </AlertDialogPrimitive.Title>
))
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName

const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description
    ref={ref}
    className={cn('text-body text-l-l2 mt-2.5 pb-3', className)}
    {...props}
  />
))
AlertDialogDescription.displayName =
  AlertDialogPrimitive.Description.displayName

const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action> & {
    variant?: ButtonProps['variant']
    withButtonVariant?: boolean
  }
>(
  (
    { className, variant = 'primary', withButtonVariant = true, ...props },
    ref,
  ) => (
    <AlertDialogPrimitive.Action
      ref={ref}
      className={cn(
        withButtonVariant && buttonVariants({ variant }),
        className,
      )}
      {...props}
    />
  ),
)
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName

const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel> & {
    variant?: ButtonProps['variant']
    withButtonVariant?: boolean
  }
>(
  (
    { className, variant = 'secondary', withButtonVariant = true, ...props },
    ref,
  ) => (
    <AlertDialogPrimitive.Cancel
      ref={ref}
      className={cn(
        withButtonVariant && buttonVariants({ variant }),
        className,
      )}
      {...props}
    />
  ),
)
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName

export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
}
