import * as React from 'react'

import { cn } from '@paladise/ui/lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isInvalid?: boolean
  placeholderExtra?: React.ReactNode
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isInvalid, placeholderExtra, ...props }, ref) => {
    return (
      <div className="relative">
        <textarea
          className={cn(
            'border-background-2nd bg-background-2nd placeholder:text-label-l3 text-md flex min-h-[80px] w-full resize-none rounded-[12px] border px-3 py-2.5 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            isInvalid && 'border-alert',
            className,
          )}
          ref={ref}
          {...props}
        />
        {placeholderExtra && (
          <div className="text-label-l3 text-body text-md pointer-events-none absolute left-3 right-0 top-2.5">
            {placeholderExtra}
          </div>
        )}
      </div>
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
